<template>
  <div class="page-container insurances-page">
    <portal to="page-name">Convênios</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Convênios</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column form-group">
            <div class="input-group">
              <input type="text" id="filter-search" name="filter-search"
                     class="form-input" v-model="search"
                     maxlength="100" autocomplete="nope"
                     placeholder="Filtrar convênio">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto">
            <button class="btn btn-primary btn-icon btn-icon-left"
                    @click="openServiceInsurance">
              <fa-icon :icon="['fal', 'plus']"/>Novo convênio
            </button>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading"></div>
        <template v-else>
          <div class="empty mt-2" v-if="data.items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h5">Convênios</p>
            <p class="empty-subtitle">
              Nenhum convênio encontrado. Verifique os filtros para realizar novas buscas
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th class="hide-sm" style="width: 40px">#</th>
              <th>Nome</th>
              <th class="hide-sm text-center">Registro ANS</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in data.items" :key="i">
              <td class="hide-sm">{{ i + 1 }}</td>
              <td>
                <span class="hover-link" v-if="deleting">{{ item.name }}</span>
                <router-link :to="editLink(item)" v-else>{{ item.name }}</router-link>
              </td>
              <td class="hide-sm text-center">
                <span v-if="item.record">{{ item.record }}</span>
                <span v-else>-</span>
              </td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-secondary mr-1"
                        :disabled="deleting"
                        @click="edit(item)">
                  <fa-icon :icon="['fal', 'pencil']"></fa-icon>
                </button>
                <button class="btn btn-sm btn-action btn-icon btn-error"
                        :class="{loading: deleting && idx === i}"
                        :disabled="deleting"
                        @click="remove(item.id, i)">
                  <fa-icon :icon="['fal', 'times']"></fa-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <div class="card-footer text-center" v-if="data.items.length > 0">
        <button
          v-if="data.hasMore && !loading"
          class="btn btn-primary btn-fixed-width"
          @click="loadMore"
          :disabled="loadingMore"
          :class="{ loading: loadingMore }"
        >Carregar mais</button>
      </div>
    </div>

    <service-insurances-modal
      v-if="serviceInsuranceShow"
      :show="serviceInsuranceShow"
      @close="closeServiceInsurance"
    />
  </div>
</template>

<script>
import ServiceInsurancesModal from './modals/ServiceInsurances.vue';

export default {
  components: {
    ServiceInsurancesModal,
  },
  data() {
    return {
      path: '/insurances',
      idx: null,
      debounceTimeout: null,
      loading: false,
      loadingMore: false,
      deleting: false,
      search: '',
      serviceInsuranceShow: false,
      data: {
        items: [],
        hasMore: false,
        limit: 30,
        offset: 0,
      },
    };
  },
  watch: {
    search: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.reload, 1000);
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async reload() {
      this.data.offset = 0;
      await this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.search.trim().length > 0) {
        params.search = this.search.trim();
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    edit(item) {
      this.$router.push(`/clinical/insurances/${item.id}/edit`);
    },
    remove(id, i) {
      if (this.deleting) {
        return;
      }

      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este registro?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.deleting = true;
              this.idx = i;
              close();
              this.$http
                .delete(`${this.path}/${id}`)
                .then(() => {
                  this.data.items.splice(i, 1);
                })
                .catch(() => {
                  this.$toast.error('Ocorreu um erro. Tente novamente!');
                })
                .finally(() => {
                  this.deleting = false;
                });
            },
          },
        ],
      });
    },
    editLink(item) {
      return `/clinical/insurances/${item.id}/edit`;
    },
    openServiceInsurance() {
      this.serviceInsuranceShow = true;
    },
    closeServiceInsurance() {
      this.load();
      this.serviceInsuranceShow = false;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .insurances-page {
    .btn-fixed-width {
      font-size: $font-size-lg;
      height: 2.2rem;
      margin: $layout-spacing-lg 0;
      width: 11rem;
    }
  }
</style>
