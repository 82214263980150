<template>
  <div class="insurance-plan-list">
    <div class="loading loading-lg mt-2" v-if="loading" />
    <template v-else>
      <div class="columns mt-2">
        <div class="column form-group">
          <div class="input-group">
            <input type="text" id="filter-search"
                   name="filter-search" class="form-input"
                   v-model="search" maxlength="100"
                   autocomplete="nope" placeholder="Filtrar plano">
            <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                    tabindex="-1">
              <fa-icon :icon="['fal', 'search']"/>
            </button>
          </div>
        </div>
        <div class="column col-auto">
          <button class="btn btn-primary btn-icon btn-icon-left"
                  @click="open">
            <fa-icon :icon="['fal', 'plus']"/>Novo plano
          </button>
        </div>
      </div>
      <div class="empty" v-if="filteredPlans.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Planos</p>
        <p class="empty-subtitle">
          Nenhum plano encontrado.
          Verifique os filtros para realizar novas buscas ou realize o cadastro
        </p>
      </div>
      <table class="table table-striped table-hover" v-else>
        <thead>
        <tr>
          <th class="hide-sm">#</th>
          <th>Nome</th>
          <th>Registro</th>
          <th>Tipo</th>
          <th>Status</th>
          <th style="width:40px"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in filteredPlans" :key="i">
          <td class="hide-sm">{{ i + 1 }}</td>
          <td>
            <span class="hover-link"
                  @click="edit(item.id)">{{ item.name }}</span>
          </td>
          <td>{{ item.record || '-' }}</td>
          <td>{{ getTypeName(item.type) }}</td>
          <td class="hide-sm">{{ item.active ? 'Ativo': 'Inativo' }}</td>
          <td class="text-right">
            <button class="btn btn-sm btn-action btn-icon btn-secondary tooltip"
                    @click="edit(item.id)" data-tooltip="Editar">
              <fa-icon :icon="['fal', 'pencil']"></fa-icon>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <plan-modal
        v-if="planModal.show"
        :show="planModal.show"
        :id="planModal.id"
        :insurance="insurance"
        @close="close"
      />
    </template>
  </div>
</template>

<script>
import { getName as getTypeName } from 'src/data/insurance-types';
import formMixin from 'src/mixins/form';
import fuzzy from 'fuzzy';
import PlanModal from './modals/Plan.vue';

export default {
  mixins: [formMixin],
  components: {
    PlanModal,
  },
  props: {
    insurance: {
      type: Object,
    },
  },
  data() {
    return {
      search: '',
      loading: false,
      items: [],
      getTypeName,
      planModal: {
        id: '',
        show: false,
      },
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    filteredPlans() {
      let { items } = this;
      if (this.search.trim()) {
        const results = fuzzy.filter(this.search, items, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        items = results.map(({ original }) => original);
      }

      return items;
    },
  },
  methods: {
    load() {
      this.loading = true;

      const params = {
        active: true,
        limit: 0,
      };

      return this.$http
        .get(`/insurances/${this.insurance.id}/plans`, { params })
        .then(({ data }) => {
          this.items = data.items;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    open() {
      this.planModal.id = '';
      this.planModal.show = true;
    },
    edit(id) {
      this.planModal.id = id;
      this.planModal.show = true;
    },
    close() {
      this.planModal.show = false;
      this.load();
    },
  },
};
</script>

<style lang="scss">
.insurance-plan-list{
  //
}
</style>
