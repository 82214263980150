<template>
  <div class="insurance-setting-tiss">
    <div class="columns">
      <div class="column col-2 form-group">
        <label class="form-label" for="tiss">Faturamento TISS</label>
        <select id="tiss" class="form-select"
                v-model="tiss.enabled" @change="$v.tiss.$reset()">
          <option :value="false">Inativo</option>
          <option :value="true">Ativo</option>
        </select>
      </div>
      <div class="column col-3 form-group"
           :class="{'has-error': $v.tiss.version.$error}">
        <label class="form-label" for="version">Versão TISS</label>
        <select id="version" class="form-select"
                v-model="tiss.version"
                @blur="$v.tiss.version.$touch()">
          <option value="">[Selecione]</option>
          <option v-for="(text, value) in tissVersions"
                  :value="value" :key="value">{{ text }}</option>
        </select>
      </div>
      <div class="column col-2 form-group"
           :class="{'has-error': $v.tiss.xml.batch.$error}">
        <label class="form-label" for="lot">Lote atual</label>
        <dx-input-number id="lot"
                         class="form-input text-center"
                         maxlength="10"
                         v-model="tiss.xml.batch"
                         @blur="$v.tiss.xml.batch.$touch()"
        />
      </div>
      <div class="column col-5 form-group">
        <label class="form-label" for="xml">Arquivo XML</label>
        <select id="xml" class="form-select"
                v-model="tiss.xml.fields.insuranceReferral">
          <option value="true">Informar o campo guia na operadora</option>
          <option value="false">Não informar o campo guia na operadora</option>
        </select>
      </div>
    </div>
    <div class="text-info text-bold">Tipo de contrato</div>
    <div class="columns">
      <div class="column col-1 form-group">
        <label class="form-label" for="type">Tipo</label>
        <select id="type" class="form-select"
                v-model="tiss.contract.type">
          <option value="person">PF</option>
          <option value="company">PJ</option>
        </select>
      </div>
      <div class="column form-group"
           :class="{'has-error': $v.tiss.contract.code.$error}">
        <label class="form-label" for="operator">Código na operadora</label>
        <input type="text" id="operator" class="form-input"
               v-model="tiss.contract.code"
               :disabled="!isCompany"
               @blur="$v.tiss.contract.code.$touch()" />
      </div>
      <div class="column col-3 form-group"
           :class="{'has-error': $v.tiss.contract.identity.$error}">
        <label class="form-label" for="identity">CNPJ</label>
        <input type="text" id="identity" class="form-input"
               v-model="tiss.contract.identity"
               v-mask="'00.000.000/0000-00'"
               :disabled="!isCompany"
               @blur="$v.tiss.contract.identity.$touch()" />
      </div>
      <div class="column col-3 form-group"
           :class="{'has-error': $v.tiss.contract.name.$error}">
        <label class="form-label" for="name">Nome</label>
        <input type="text" id="name" class="form-input"
               v-model="tiss.contract.name"
               :disabled="!isCompany"
               @blur="$v.tiss.contract.name.$touch()" />
      </div>
      <div class="column col-2 form-group"
           :class="{'has-error': $v.tiss.contract.cnes.$error}">
        <label class="form-label" for="cnes">CNES</label>
        <input type="text" id="cnes" class="form-input"
               v-model="tiss.contract.cnes"
               :disabled="!isCompany"
               @blur="$v.tiss.contract.cnes.$touch()" />
      </div>
      <div class="column col-auto mb-2" style="align-self: flex-end">
        <button class="btn btn-gray btn-icon btn-action tooltip tooltip-left"
                data-tooltip="Preencher os campos com os dados da empresa"
                :disabled="!isCompany || loadingBranch"
                @click="getCompanyData">
          <fa-icon :icon="['fal', 'sync']" />
        </button>
      </div>
    </div>
    <div class="text-info text-bold">Preenchimento da guia</div>
    <div class="columns">
      <div class="column col-3 form-group">
        <label class="form-label" for="origin">Origem</label>
        <select id="origin" class="form-select"
                v-model="referralOrigin">
          <option v-for="(text) in tissTags"
                  :value="text.key" :key="text.key">{{ text.name }}</option>
        </select>
      </div>
      <div class="column col-3 form-group">
        <label class="form-label" for="requester">Dados do solicitante</label>
        <select id="requester" class="form-select"
                v-model="referralRequester">
          <option v-for="(text) in tissTags"
                  :value="text.key" :key="text.key">{{ text.name }}</option>
        </select>
      </div>
      <div class="column col-3 form-group">
        <label class="form-label" for="executant">Dados do executante</label>
        <select id="executant" class="form-select"
                v-model="referralExecutant">
          <option v-for="(text) in tissTags"
                  :value="text.key" :key="text.key">{{ text.name }}</option>
        </select>
      </div>
      <div class="column col-3 form-group">
        <label class="form-label" for="professional-executant">
          Profissional executante
        </label>
        <select id="professional-executant" class="form-select"
                v-model="referralProfessional">
          <option v-for="(text) in tissTags"
                  :value="text.key" :key="text.key">{{ text.name }}</option>
        </select>
      </div>
    </div>
    <div class="text-info text-bold">Guia de consulta</div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox" v-model="tiss.consultation.sadt">
        Faturar guias de consulta em SP/SADT
        <i class="form-icon"></i>
      </label>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="tiss.consultation.requiredInsuranceReferral">
        Obrigatório o preenchimento do campo "3 - Número da guia operadora"
        <i class="form-icon"></i>
      </label>
    </div>
    <div class="text-info text-bold">Guia SP/SADT</div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="tiss.sadt.authorization.requiredMainReferral">
        Obrigatório o preenchimento do campo "3 - Número da guia principal"
        <i class="form-icon"></i>
      </label>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="tiss.sadt.authorization.requiredDate">
        Obrigatório o preenchimento do campo "4 - Data da autorização"
        <i class="form-icon"></i>
      </label>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="tiss.sadt.authorization.requiredPassword">
        Obrigatório o preenchimento do campo "5 - Senha"
        <i class="form-icon"></i>
      </label>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="tiss.sadt.authorization.requiredValidity">
        Obrigatório o preenchimento do campo "6 - Data de validade"
        <i class="form-icon"></i>
      </label>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="tiss.sadt.authorization.requiredInsuranceReferral">
        Obrigatório o preenchimento do campo "7 - Número da guia operadora"
        <i class="form-icon"></i>
      </label>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="tiss.sadt.requiredRequestDate">
        Obrigatório o preenchimento do campo "22 - Data da solicitação"
        <i class="form-icon"></i>
      </label>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="tiss.sadt.fillAuthorizationDateWithServiceDate">
        Preencher o campo "4 - Data da autorização" com a data de movimentação
        <i class="form-icon"></i>
      </label>
    </div>
    <div class="d-flex">
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="tiss.sadt.authorization.fillValidityDate.enabled">
        Preencher o campo "6 - Data de validade" quantos dias após a autorização?
        <i class="form-icon"></i>
      </label>
      <div :class="{'has-error': $v.tiss.sadt.authorization.fillValidityDate.days.$error}">
        <dx-input-number id="total"
                         class="form-input input-sm text-center"
                         maxlength="3"
                         style="max-width:50px;"
                         v-model="tiss.sadt.authorization.fillValidityDate.days"
                         :disabled="!recordDigitsActive"
                         @blur="$v.tiss.sadt.authorization.fillValidityDate.days.$touch()"
        />
      </div>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="tiss.sadt.authorization.fillInsuranceReferralWithPassword">
        Preencher o campo "7 - Número da guia operadora" com "5 - Senha"
        <i class="form-icon"></i>
      </label>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="tiss.sadt.fillRequestDataWithServiceDate">
        Preencher o campo "22 - Data da solicitação" com a data de movimentação
        <i class="form-icon"></i>
      </label>
    </div>
  </div>
</template>

<script>
import { tissTags } from '@/data/tiss-tags';
import { tissVersions } from '@/data/tiss-versions';
import { minValue, required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import cnpj from '../../../../data/validators/cnpj';

export default {
  props: {
    id: {
      type: String,
    },
    insuranceId: {
      type: String,
    },
    data: {},
  },
  data() {
    return {
      tissTags,
      tissVersions,
      tiss: this.data,
      loadingBranch: false,
      referral: {
        origin: 'company_identity',
        requester: 'company_identity',
        executant: 'company_identity',
        professional: 'company_identity',
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    recordDigitsActive() {
      return this.tiss.sadt.authorization.fillValidityDate.enabled;
    },
    isCompany() {
      return this.tiss.contract.type === 'company';
    },
    referralOrigin: {
      get() {
        return this.encodeTypeValue(this.tiss.referral.origin);
      },
      set(value) {
        this.tiss.referral.origin = this.decodeTypeValue(value);
      },
    },
    referralRequester: {
      get() {
        return this.encodeTypeValue(this.tiss.referral.requester);
      },
      set(value) {
        this.tiss.referral.requester = this.decodeTypeValue(value);
      },
    },
    referralExecutant: {
      get() {
        return this.encodeTypeValue(this.tiss.referral.executant);
      },
      set(value) {
        this.tiss.referral.executant = this.decodeTypeValue(value);
      },
    },
    referralProfessional: {
      get() {
        return this.encodeTypeValue(this.tiss.referral.professional);
      },
      set(value) {
        this.tiss.referral.professional = this.decodeTypeValue(value);
      },
    },
  },
  validations() {
    const rules = {
      tiss: {
        version: {},
        xml: {
          batch: {},
        },
        contract: {
          code: {},
          identity: { cnpj },
          name: {},
          cnes: {},
        },
        sadt: {
          authorization: {
            fillValidityDate: {
              days: {},
            },
          },
        },
      },
    };

    const useTiss = !!this.tiss.enabled;

    if (useTiss && !this.tiss.xml.batch) {
      rules.tiss.xml.batch = {
        required,
        minValue: minValue(1),
      };
    }

    if (useTiss && !this.tiss.version) {
      rules.tiss.version = { required };
    }

    if (useTiss && this.tiss.contract.type === 'company') {
      if (!this.tiss.contract.code) {
        rules.tiss.contract.code = { required };
      }
      if (!this.tiss.contract.identity) {
        rules.tiss.contract.identity = { required };
      }
      if (!this.tiss.contract.name) {
        rules.tiss.contract.name = { required };
      }
      if (!this.tiss.contract.cnes) {
        rules.tiss.contract.cnes = { required };
      }
    }

    if (useTiss
      && this.tiss.sadt.authorization.fillValidityDate.enabled) {
      rules.tiss.sadt.authorization.fillValidityDate.days = {
        required,
        minValue: minValue(1),
      };
    }

    return rules;
  },
  methods: {
    validity() {
      this.$v.tiss.$touch();
      return this.$v.tiss.$error;
    },
    decodeTypeValue(str) {
      const [type, value] = str.split('_');
      return { type, value };
    },
    encodeTypeValue({ type, value }) {
      return [type, value].join('_');
    },
    getCompanyData() {
      this.loadingBranch = true;

      return this.$http.get(`/branches/${this.user.branch.id}`)
        .then(({ data }) => {
          if (data.identity) {
            if (data.identity.type === 'cnpj') {
              this.tiss.contract.code = data.identity.value;
              this.tiss.contract.identity = data.identity.value;
            }
          }
          if (data.companyName) {
            this.tiss.contract.name = data.companyName;
          }
          if (data.branch) {
            this.tiss.contract.cnes = data.branch.cnes;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loadingBranch = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.insurance-setting-tiss {
  margin-top: $layout-spacing;
}
</style>
