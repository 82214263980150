<template>
  <div class="page-container insurance-page">
    <portal to="page-name">Cadastro de convênio</portal>
    <div class="loading loading-lg mt-2" v-if="loading"></div>
    <div class="card card-page" v-else>
      <div class="card-header">
        <div class="card-title">
          Cadastro de convênio
          <span v-if="form.name"> - </span>
          <small class="text-secondary">{{ form.name }}</small>
        </div>
      </div>
      <div class="card-body">
        <st-tabs ref="insurance-tabs">
          <st-tab id="tab-insurance" name="Dados principais">
            <div class="columns">
              <div class="column col-8 col-md-12 col-sm-12 form-group">
                <label for="name" class="form-label">Nome</label>
                <input type="text" id="name" readonly
                       v-model="form.name" class="form-input"
                       placeholder="Nome do convênio" autocomplete="nope">
              </div>
              <div class="column col-2 col-md-12 col-sm-12 form-group">
                <label for="return-days" class="form-label">Dias de retorno</label>
                <input type="text" id="return-days" name="return-days"
                       v-model="form.returnDays" class="form-input text-center"
                       placeholder="00" autocomplete="nope" v-mask="'00'">
              </div>
              <div class="column col-2 col-md-12 col-sm-12 form-group">
                <label for="daily-limit" class="form-label">Limite diário</label>
                <input type="text" id="daily-limit" name="daily-limit"
                       v-model="form.dailyLimit" class="form-input text-center"
                       placeholder="00" autocomplete="nope" v-mask="'00'">
              </div>
              <div class="column col-12 form-group">
                <label class="form-label">Observações</label>
                <textarea id="notes" class="form-input" rows="4"
                          v-model="form.notes"></textarea>
              </div>
            </div>
          </st-tab>
          <st-tab id="tab-plan" name="Planos">
            <plans
              v-if="form.id"
              :insurance="form"
            />
          </st-tab>
        </st-tabs>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1"
                @click="save" :disabled="saving"
                :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.push('/clinical/insurances')">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import { mergeFrom } from '@/helpers/object';
import formMixin from '../../../mixins/form';
import Plans from './Plans.vue';

export default {
  components: {
    Plans,
  },
  mixins: [formMixin],
  data() {
    return {
      path: '/insurances',
      loading: false,
      saving: false,
      form: this.blankForm(),
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        type: { required },
        record: { required },
        active: { required },
      },
    };
  },
  async mounted() {
    this.form.id = this.$route.params.id;
    this.load(this.form.id);
  },
  methods: {
    load() {
      this.loading = true;

      return this.$http
        .get(`${this.path}/${this.form.id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      this.saving = true;

      const data = this.clone(this.form);

      if (!data.companyName) {
        delete data.companyName;
      }
      if (!data.identity.value) {
        delete data.identity;
      }
      if (!data.record) {
        delete data.record;
      }

      this.$http.put(`${this.path}/${this.form.id}`, data)
        .then(() => {
          this.$toast.show('Registro salvo!');
        })
        .catch(() => {
          this.$toast.show('Ocorreu um erro. Tente novamente!', { type: 'error' });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    blankForm() {
      return {
        id: '',
        identityId: '',
        identity: {
          type: '',
          value: '',
        },
        name: '',
        companyName: '',
        type: '',
        record: '',
        returnDays: '',
        dailyLimit: '',
        notes: '',
      };
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .insurance-page {
  }
</style>
