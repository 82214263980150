export const tissTags = [
  {
    name: 'CPF do profissional',
    key: 'person_identity',
  },
  {
    name: 'Código do profissional na operadora',
    key: 'person_code',
  },
  {
    name: 'CNPJ do hosp/clínica',
    key: 'company_identity',
  },
  {
    name: 'Código do hosp/clínica na operadora',
    key: 'company_code',
  },
];

export default tissTags;
