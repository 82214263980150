<template>
  <dx-modal title="Localize seu convênio"
            id="modal-insurance"
            v-if="show"
            :value="show"
            @input="close"
  >
    <div class="columns">
      <div class="column col-12 form-group">
        <div class="input-group">
          <input type="text" id="filter-insurance" class="form-input"
                 v-model="search" maxlength="100" autocomplete="nope"
                 placeholder="Nome ou CNPJ. Ex: &quot;Bradesco*Planos&quot;" v-focus>
          <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                  :class="{loading: loading}" tabindex="-1">
            <fa-icon :icon="['fal', 'search']"/>
          </button>
        </div>
        <item
          v-for="(item, i) in items"
          :data="item"
          :key="i"
          :selected="selected"
          @select="selected = $event"
          @close="close"
        />
      </div>
    </div>
    <template slot="footer">
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import item from './ServiceInsurance.vue';

export default {
  components: {
    item,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      search: '',
      selected: null,
      items: [],
    };
  },
  watch: {
    search: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.searchInsurance, 800);
      },
      deep: true,
    },
  },
  methods: {
    searchInsurance() {
      const value = this.search.trim();
      const params = {
        search: value,
        limit: 10,
      };
      if (value.length >= 3) {
        this.loading = true;
        this.$http
          .get('/services/insurances', { params })
          .then(({ data }) => {
            this.items = data.items;
          })
          .catch(() => {})
          .then(() => {
            this.loading = false;
          });
      } else {
        this.items = [];
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";

#insurance {
}
</style>
