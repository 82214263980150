<template>
  <div class="insurances-item-page">
    <div class="insurance-list tile tile-centered"
         @click="create">
      <div class="tile-content" :class="{ 'text-gray': selected && selected !== data.id }">
        {{ data.name }}
      </div>
      <div class="tile-action">
        <span :class="{loading: selected === data.id}"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    selected: {
      type: String,
    },
  },
  data() {
    return {
      saving: false,
    };
  },
  methods: {
    loadInsuranceItem(id) {
      return this.$http
        .get(`/services/insurances/${id}`)
        .catch(() => {});
    },
    async create() {
      if (this.saving || this.selected) {
        return;
      }

      this.$emit('select', this.data.id);

      const params = {
        identityId: this.data.id,
      };

      const { data: found } = await this.$http
        .get('/insurance-plans', { params });

      if (found && found.items.length > 0) {
        this.$toast.show('Convênio já cadastrado', { type: 'error' });
        this.$emit('select', null);
        return;
      }

      const { data: insurance } = await this.loadInsuranceItem(this.data.id);

      const dataParams = {
        identityId: this.data.id,
        name: this.data.name,
        companyName: this.data.companyName,
        active: true,
        type: this.data.insurance.type,
        record: this.data.insurance.record,
        cellphone: insurance.cellphone,
        phone: insurance.phone,
        imageUrl: insurance.imageUrl || null,
        address: insurance.addresses && insurance.addresses.length > 0
          ? insurance.addresses[0] : null,
        plan: {
          id: insurance.insurance.plans[0].id,
          name: insurance.insurance.plans[0].name,
          type: this.data.insurance.type,
          record: this.data.insurance.record,
          identity: null,
          active: true,
        },
      };

      if (insurance.identity && insurance.identity.value) {
        dataParams.identity = insurance.identity;
        dataParams.plan.identity = insurance.identity;
      }

      const request = found.items.length === 0
        ? this.$http.post('/insurances', dataParams)
        : this.$http.put(`/insurances/${found.id}`, dataParams);

      // eslint-disable-next-line consistent-return
      return request
        .catch(() => {})
        .then(() => {
          this.$emit('select', null);
          this.$toast.show('Registro salvo');
          this.$emit('close');
        });
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.insurances-item-page {
  .insurance-list {
    margin-top: $layout-spacing-sm;
    padding: $layout-spacing-sm;
    border: $border-width solid $border-color;
    cursor: pointer;
    &:hover {
      background-color: lighten($gray-color-light, 5);
    }
    .tile-action {
      width: $layout-spacing;
    }
  }
}
</style>
