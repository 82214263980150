<template>
  <div class="insurance-setting-rules">
    <div class="text-info text-bold">Carteirinha</div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="general.record.required">
        Exigir preenchimento da carteirinha
        <i class="form-icon"></i>
      </label>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="general.record.requiredValidity">
        Exigir preenchimento da validade
        <i class="form-icon"></i>
      </label>
    </div>
    <div>
      <div class="form-group d-flex">
        <label class="form-switch">
          <input type="checkbox"
                 v-model="general.record.digits.enabled">
          Número de dígitos da carteirinha:
          <i class="form-icon"></i>
        </label>
        <div class="mr-2" style="max-width: 50px;"
             :class="{'has-error': $v.general.record.digits.min.$error}">
          <dx-input-number id="total"
                           class="form-input input-sm text-center"
                           maxlength="3"
                           placeholder="mín"
                           :disabled="!recordDigitsActive"
                           @blur="$v.general.record.digits.min.$touch()"
                           v-model="general.record.digits.min"
          />
        </div>
        <div style="max-width: 50px;"
             :class="{'has-error': $v.general.record.digits.max.$error}">
          <dx-input-number id="total"
                           class="form-input input-sm text-center"
                           :class="{'has-error': $v.general.record.digits.max.$error}"
                           maxlength="3"
                           style="max-width: 50px;"
                           placeholder="máx"
                           :disabled="!recordDigitsActive"
                           @blur="$v.general.record.digits.max.$touch()"
                           v-model="general.record.digits.max"
          />
        </div>
      </div>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="general.record.fillValidityWithServiceDate">
        Preencher a data de validade da carteirinha com a data do atendimento
        <i class="form-icon"></i>
      </label>
    </div>
    <div class="text-info text-bold mt-2">Faturamento</div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="general.billing.blockValues">
        Bloquear edição dos campos valores
        <i class="form-icon"></i>
      </label>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="general.billing.hideInsuranceValue">
        Ocultar valores dos convênios
        <i class="form-icon"></i>
      </label>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="general.billing.allowDifferentReferralTypes">
        Permitir faturar diferentes guias de atendimento na mesma fatura
        <i class="form-icon"></i>
      </label>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="general.billing.billingForIndividualSessions">
        Faturamento por sessões individualizadas
        <i class="form-icon"></i>
      </label>
    </div>
    <div class="d-flex">
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="general.billing.return.enabled">
        Solicitar senha de liberação para retorno acima do prazo máximo
        <i class="form-icon"></i>
      </label>
    </div>
    <div class="d-flex">
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="general.billing.accounts.enabled">
        Quantidade de guias por fatura
        <i class="form-icon"></i>
      </label>
      <div class="mr-2" style="max-width: 50px;"
           :class="{'has-error': $v.general.billing.accounts.quantity.$error}">
        <dx-input-number id="total"
                         class="form-input input-sm text-center"
                         maxlength="3"
                         :disabled="!billingAccountsActive"
                         @blur="$v.general.billing.accounts.quantity.$touch()"
                         v-model="general.billing.accounts.quantity"
        />
      </div>
    </div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               @change="enabledTransition"
               v-model="transition.enabled">
        Utilizar fechamento automático de faturas
        <i class="form-icon"></i>
      </label>
    </div>
    <div class="timeline-group" v-if="transition.enabled">
      <div class="d-flex">
        <label class="form-radio">
          <input name="radio-transition"
                 type="radio"
                 v-model="transition.type"
                 @change="changeTransition"
                 value="weekly">
          <i class="form-icon"></i>
          Semanal
        </label>
        <label class="form-radio">
          <input name="radio-transition"
                 type="radio"
                 v-model="transition.type"
                 @change="changeTransition"
                 value="monthly">
          <i class="form-icon"></i>
          Mensal
        </label>
        <label class="form-radio">
          <input name="radio-transition"
                 type="radio"
                 v-model="transition.type"
                 @change="changeTransition"
                 value="custom">
          <i class="form-icon"></i>
          Cronograma
        </label>
      </div>
      <div class="divider" />
      <div class="columns">
        <div class="column col-3 col-md-4 col-sm-12" v-if="transition.type === 'weekly'">
          <label class="form-label">Informe o dia da semana</label>
          <select class="form-select"
                  @change="addTransition"
                  v-model="transition.value">
            <option value="">Selecione</option>
            <option :value="i" v-for="(day, i) in transition.weekDays" :key="i">
              {{ day }}
            </option>
          </select>
        </div>
        <div class="column col-3 col-md-4 col-sm-12" v-if="transition.type === 'monthly'">
          <label class="form-label">Informe o dia do mês</label>
          <select class="form-select"
                  v-model="transition.value" @change="addTransition">
            <option value="">Selecione</option>
            <option :value="item.value" v-for="(item, i) in filteredMonthlyDays" :key="i">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="column col-3 col-md-4 col-sm-12" v-if="transition.type === 'custom'"
             :class="{'has-error': $v.transition.value.$error}">
          <label class="form-label">Informe o cronograma</label>
          <div class="input-group">
            <dx-input-date
              id="custom-date"
              class="form-input"
              v-model="transition.value"
              @keypress.enter="addTransition"
            />
            <button class="btn btn-gray btn-icon input-group-btn" @click="addTransition">
              <fa-icon :icon="['fal', 'plus-circle']" />
            </button>
          </div>
          <template v-if="$v.transition.value.$error">
            <div class="form-input-hint"
                 v-if="!$v.transition.value.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.transition.value.date">Data inválida</div>
            <div class="form-input-hint"
                 v-else-if="!$v.transition.value.minDate">
              A data não pode ser inferior a hoje!
            </div>
          </template>
        </div>
        <div class="column col-auto"
             v-if="general.billing.transitions && general.billing.transitions.length > 0">
          <label class="form-label">{{ getTypeName() }}</label>
          <span class="tooltip c-hand" data-tooltip="Clique para remover"
                v-for="(item, i) in general.billing.transitions" :key="i">
            <span class="chip bg-info" @click="removedTransition(i)">
              {{ getTransitionName(item) }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="text-info text-bold mt-2">Guia Prestador</div>
    <label class="form-radio">
      <input name="radio-provider-guide"
             type="radio"
             v-model="general.providerReferral.mode"
             value="manual">
      <i class="form-icon"></i>Informar o número da guia prestador manualmente
    </label>
    <div class="d-flex">
      <label class="form-radio">
        <input name="radio-provider-guide"
               type="radio"
               v-model="general.providerReferral.mode"
               value="automatic">
        <i class="form-icon"></i>
        Gerar o número da guia prestador automaticamente. Guia atual:
      </label>
      <div class="mr-2" style="max-width: 220px;"
           :class="{'has-error': $v.general.providerReferral.lastValue.$error}">
        <input type="text"
               class="form-input input-sm text-center"
               :disabled="!providerReferralActive"
               @blur="$v.general.providerReferral.lastValue.$touch()"
               v-model="general.providerReferral.lastValue"
               v-mask="'000000000000000'"
        />
      </div>
    </div>
    <label class="form-radio">
      <input name="radio-provider-guide"
             type="radio"
             v-model="general.providerReferral.mode"
             value="password">
      <i class="form-icon"></i>Preencher a guia prestador com a senha
    </label>
    <label class="form-radio">
      <input name="radio-provider-guide"
             type="radio"
             v-model="general.providerReferral.mode"
             value="insurance_referral">
      <i class="form-icon"></i>Preencher a guia prestador com a guia operadora
    </label>
    <div class="divider" />
    <div class="text-info text-bold">ASO</div>
    <div>
      <label class="form-switch d-inline-block">
        <input type="checkbox"
               v-model="general.aso.requiredClient">
        Exigir preenchimento da empresa na agenda
        <i class="form-icon"></i>
      </label>
    </div>
    <div class="divider" />
    <div class="text-info text-bold">Tabelas</div>
    <div class="columns">
      <div class="column col-6 col-md-6 col-sm-12 form-group">
        <label class="form-label">Tabela de procedimentos</label>
        <select class="form-select"
                v-model="general.table.procedureId">
          <option value="">[Selecione]</option>
          <option v-for="(item, i) in cbhpmTables"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-6 col-md-6 col-sm-12 form-group">
        <label class="form-label">Tabela de exames</label>
        <select class="form-select"
                v-model="general.table.examId">
          <option value="">[Selecione]</option>
          <option v-for="(item, i) in cbhpmTables"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-6 col-md-6 col-sm-12 form-group">
        <label class="form-label">Tabela de materiais</label>
        <select id="product-price" class="form-select"
                v-model="general.table.materialId">
          <option value="">[Selecione]</option>
          <option v-for="(item, i) in productPriceList"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-6 col-md-6 col-sm-12 form-group">
        <label class="form-label">Tabela de medicamentos</label>
        <select id="product-price" class="form-select"
                v-model="general.table.medicineId">
          <option value="">[Selecione]</option>
          <option v-for="(item, i) in productPriceList"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
      </div>
    </div>
    <div class="divider" />
    <div class="text-info text-bold">Financeiro</div>
    <div class="columns">
      <div class="column col-4 col-md-12 form-group">
        <label class="form-label">Forma de pagamento</label>
        <select class="form-select"
                v-model="general.financial.methodId">
          <option value="">[Selecione]</option>
          <option v-for="(item, i) in methodList"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-4 col-md-12 form-group">
        <label class="form-label">Categoria</label>
        <select class="form-select"
                v-model="general.financial.categoryId">
          <option value="">[Selecione]</option>
          <option v-for="(item, i) in categoryList"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-4 col-md-12 form-group">
        <label class="form-label">
          Conta bancária / caixa
        </label>
        <select class="form-select"
                v-model="general.financial.bankAccountId">
          <option value="">[Selecione]</option>
          <option v-for="(item, i) in bankAccountList"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
      </div>
    </div>
    <div class="divider" />
    <div class="text-info text-bold">Impostos</div>
    <div class="columns">
      <div class="column col-md-4 form-group">
        <label class="form-label">ISS</label>
        <dx-input-number class="form-input"
                         v-model="general.taxes.iss"
                         :precision="2"
        />
      </div>
      <div class="column col-md-4 form-group">
        <label class="form-label">IRPJ</label>
        <dx-input-number class="form-input"
                         v-model="general.taxes.irpj"
                         :precision="2"
        />
      </div>
      <div class="column col-md-4 form-group">
        <label class="form-label">PIS</label>
        <dx-input-number class="form-input"
                         v-model="general.taxes.pis"
                         :precision="2"
        />
      </div>
      <div class="column col-md-4 form-group">
        <label class="form-label">CSLL</label>
        <dx-input-number class="form-input"
                         v-model="general.taxes.csll"
                         :precision="2"
        />
      </div>
      <div class="column col-md-4 form-group">
        <label class="form-label">COFINS</label>
        <dx-input-number class="form-input"
                         v-model="general.taxes.cofins"
                         :precision="2"
        />
      </div>
      <div class="column col-md-4 form-group">
        <label class="form-label">ME/EPP</label>
        <dx-input-number class="form-input"
                         v-model="general.taxes.epp"
                         :precision="2"
        />
      </div>
      <div class="column col-md-4 form-group">
        <label class="form-label">Admin</label>
        <dx-input-number class="form-input"
                         v-model="general.taxes.admin"
                         :precision="2"
        />
      </div>
      <div class="column col-md-4 form-group">
        <label class="form-label">Outros</label>
        <dx-input-number class="form-input"
                         v-model="general.taxes.others"
                         :precision="2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';
import moment from 'moment';

export default {
  props: {
    id: {
      type: String,
    },
    insuranceId: {
      type: String,
    },
    data: {},
  },
  data() {
    return {
      bankAccountList: [],
      categoryList: [],
      cbhpmTables: [],
      methodList: [],
      productPriceList: [],
      general: this.data,
      transition: {
        enabled: false,
        weekDays: [
          'Domingo',
          'Segunda-feira',
          'Terça-feira',
          'Quarta-feira',
          'Quinta-feira',
          'Sexta-feira',
          'Sábado',
        ],
        monthDays: [],
        type: 'weekly',
        value: '',
      },
    };
  },
  mounted() {
    this.loadCategories();
    this.loadBankAccounts();
    this.loadMethods();
    this.loadProductPrices();
    this.loadCBHPM();
    this.loadMonthDays();
    this.init();
  },
  computed: {
    recordDigitsActive() {
      return this.general.record.digits.enabled;
    },
    billingAccountsActive() {
      return this.general.billing.accounts.enabled;
    },
    billingReturnActive() {
      return this.general.billing.return.enabled;
    },
    providerReferralActive() {
      return this.general.providerReferral.mode === 'automatic';
    },
    filteredMonthlyDays() {
      return this.transition.monthDays
        .filter(item => this.general.billing.transitions
          .every(({ value }) => value.toString() !== item.value.toString()));
    },
  },
  validations() {
    const rules = {
      general: {
        record: {
          digits: {
            min: {},
            max: {},
          },
        },
        billing: {
          accounts: {
            quantity: {},
          },
        },
        providerReferral: {
          lastValue: {},
        },
      },
      transition: {
        value: {},
      },
    };

    if (this.general.record.digits.enabled) {
      rules.general.record.digits.min = {
        required,
        minValue: minValue(1),
      };
      rules.general.record.digits.max = {
        required,
        minValue: minValue(1),
      };
      if (this.general.record.digits.min > 0) {
        rules.general.record.digits.max = {
          required,
          minValue: minValue(this.general.record.digits.min),
        };
      }
    }

    if (this.general.billing.accounts.enabled) {
      rules.general.billing.accounts.quantity = {
        required,
        minValue: minValue(1),
      };
    }

    if (this.general.providerReferral.mode === 'automatic') {
      rules.general.providerReferral.lastValue = {
        required,
        minValue: minValue(1),
      };
    }

    if (this.transition.enabled && this.transition.type === 'custom') {
      rules.transition.value = {
        required,
        date,
        minDate: minDate(),
      };
    }

    return rules;
  },
  methods: {
    init() {
      if (Array.isArray(this.general.billing.transitions)
        && this.general.billing.transitions.length > 0) {
        const [transition] = this.general.billing.transitions;
        this.transition.enabled = transition.enabled;
        this.transition.type = transition.type;
      }
    },
    validity() {
      this.$v.general.$touch();
      return this.$v.general.$error;
    },
    loadBankAccounts() {
      const params = {
        active: true,
        limit: 0,
      };

      this.$http.get('/bank-accounts', { params })
        .then(({ data }) => {
          this.bankAccountList = data.items;
        })
        .catch(() => {});
    },
    loadProductPrices() {
      const params = {
        active: true,
        limit: 0,
      };

      this.$http.get('/product-price-tables', { params })
        .then(({ data }) => {
          this.productPriceList = data.items;
        })
        .catch(() => {});
    },
    loadCategories() {
      const params = {
        active: true,
        type: 'revenue',
        limit: 0,
      };

      this.$http.get('/categories', { params })
        .then(({ data }) => {
          this.categoryList = data.items;
        })
        .catch(() => {});
    },
    loadCBHPM() {
      const params = {
        active: true,
        limit: 0,
      };

      this.$http.get('/cbhpm-tables', { params })
        .then(({ data }) => {
          this.cbhpmTables = data.items;
        })
        .catch(() => {});
    },
    loadMethods() {
      const params = {
        active: true,
        limit: 0,
      };

      this.$http.get('/payment-methods', { params })
        .then(({ data }) => {
          this.methodList = data.items;
        })
        .catch(() => {});
    },
    loadMonthDays() {
      const items = [{ value: 0, name: 'Último dia' }];
      for (let i = 1; i <= 31; i += 1) {
        items.push({ value: i, name: i.toString() });
      }
      this.transition.monthDays = items;
    },
    addTransition() {
      if (this.transition.type === 'custom') {
        this.$v.transition.value.$touch();
        if (this.$v.transition.value.$error) {
          return;
        }

        const found = this.general.billing.transitions
          .find(({ value }) => value === this.transition.value);

        if (!found) {
          this.general.billing.transitions.push({
            enabled: this.transition.enabled,
            type: this.transition.type,
            value: this.transition.value,
          });
        }

        this.$nextTick(() => {
          document.getElementById('custom-date').focus();
        });
      } else if (this.transition.type === 'monthly') {
        this.general.billing.transitions.push({
          enabled: this.transition.enabled,
          type: this.transition.type,
          value: this.transition.value,
        });
      } else if (this.transition.value !== '') {
        this.general.billing.transitions = [{
          enabled: this.transition.enabled,
          type: this.transition.type,
          value: this.transition.value,
        }];
      }

      this.general.billing.transitions.sort((a, b) => {
        if (a.value > b.value) return 1;
        if (a.value < b.value) return -1;
        return 0;
      });

      this.clearTransition();
    },
    clearTransition() {
      this.transition.value = '';
      this.$v.transition.$reset();
    },
    removedTransition(idx) {
      this.general.billing.transitions.splice(idx, 1);
    },
    enabledTransition() {
      this.transition.type = 'monthly';
      this.general.billing.transitions = [];
      this.clearTransition();
      if (this.transition.enabled) {
        this.general.billing.transitions = [{
          enabled: true,
          type: 'monthly',
          value: 0,
        }];
      }
    },
    changeTransition() {
      this.general.billing.transitions = [];
      this.clearTransition();
      if (this.transition.type === 'weekly') {
        this.addTransition();
      }
    },
    getTransitionName(item) {
      if (item.type === 'weekly') {
        return this.transition.weekDays[item.value];
      } if (item.type === 'custom') {
        return moment(item.value).format('DD/MM/YYYY');
      }
      return item.value === 0 ? 'Último dia do mês' : item.value;
    },
    getTypeName() {
      if (this.transition.type === 'weekly') {
        return 'Fechar todo(a)';
      } if (this.transition.type === 'monthly') {
        return 'Realizar o fechamento no(s) dia(s)';
      }
      return 'Realizar o fechamento conforme cronograma';
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.insurance-setting-rules {
  margin-top: $layout-spacing;
  .text-bold {
    font-weight: 600;
  }
  .timeline-group {
    background-color: $gray-color-ultra-light;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin-top: $layout-spacing-sm;
    padding: $layout-spacing;
    .divider {
      border-top-color: $gray-color-light;
    }
  }
}
</style>
