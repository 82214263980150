export const tissVersions = {
  30200: '3.02.00',
  30201: '3.02.01',
  30202: '3.02.02',
  30301: '3.03.01',
  30302: '3.03.02',
  30303: '3.03.03',
  30400: '3.04.00',
  30401: '3.04.01',
  30500: '3.05.00',
  40000: '4.00.00',
  40001: '4.00.01',
  40100: '4.01.00',
};

export function getName(value) {
  return tissVersions[value];
}
